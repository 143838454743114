<template>
  <div id="myChart" ref="myChart" :style="{width: '100%', height: '35px'}"></div>
</template>
<script>
const echarts = require('echarts')
export default {
  props: ['n5data','productid', 'linecolor'],
  data () {
    return {}
  },
  mounted(){
    this.drawLine();
  },
  methods: {
    drawLine() {
      let myChart= echarts.init(this.$refs.myChart)
      myChart.setOption({
        grid: {
          left: '0',
          right: '0',
          top: '0',
          bottom: '0'
        },
        xAxis: [
          {
            type: 'category',
            show: false,
            boundaryGap: false,
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            show: false
          }
        ],
        series: [
          {
            smooth: true,
            type: 'line',
            symbol: 'none',
            areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [  // 渐变颜色
                    {
                      offset: 0,
                      color: this.linecolor,
                    },
                    {
                      offset: 1,
                      color: '#ffffff',
                    },
                  ],
                  global: false,
                }
            },
            data: this.n5data,
            lineStyle: {
              normal: {
                color: this.linecolor,
              }
            }
          }
        ]
      });
    }
  }
}
</script>

